
.signup {
  .logoClass {
    text-decoration: none;
    color: white;
  }

  .is-danger {
    margin-bottom: 0 !important;
    margin-top: 10px;
  }
  .is-green {
    margin-bottom: 0 !important;
    margin-top: 10px;
  }
  .header {
    background: #2e305c;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      /* identical to box height */
      padding: 22px 0;
      text-align: center;
      color: #ffffff;
      span {
        color: #fa8c28;
        font-weight: 800;
      }
    }
  }
  .body {
    background: #ffffff;
    padding: 64px;
    @media screen and (max-width: 768px) {
      padding: 20px;
    }
    .form-card {
      max-width: 824px;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 1px 4px 34px rgb(46 48 92 / 20%);
      padding: 30px;
      h1 {
        padding-top: 48px;
        padding-bottom: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        color: #2e305c;
      }
      p {
        text-align: center;
        font-style: normal;
        font-weight: 300;
        font-size: 14px !important;
        line-height: 17px;
        /* identical to box height */

        color: #000000;
        a {
          color: #fa8c28;
          font-weight: 600;
        }
      }
      .custom-form {
        padding: 18px 0px;
        .container {
          padding: 0px;
          @media screen and (max-width: 768px) {
            padding: 0;
          }
          .row {
            .custom-input {
              margin: 0px;
            }
          }
          .submit-btn {
            margin-top: 20px;
            background: #fa8c28;
            border-radius: 4px;
            padding: 23px 24px;
            width: 100%;
            height: 64px;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;

            color: #ffffff;
            &:hover {
              opacity: 0.9;
            }
            &.disabled {
              background-color: #eeeeee;
              cursor: not-allowed;
              pointer-events: none;
            }
          }
        }
      }
    }
    .footer {
      text-align: center;
      padding-top: 22px;
      padding-bottom: 45px;
      a {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-decoration-line: underline;

        color: #2e305c;
      }
    }
  }
}
